<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                    @change="get_bodegas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="get_bodegas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Bodega
                  <v-autocomplete
                    v-model="filtro.deposito"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="bodegas"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0"></v-col>
                <v-col cols="6" md="2" class="py-1">
                  Nº inventario
                  <v-text-field
                    v-model.trim="filtro.inventario"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Nº artículo
                  <v-text-field
                    v-model.trim="filtro.articulo"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col :cols="big ? 12 : 6" :sm="big ? 5 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="filtro.fecha"
                    :limpiar.sync="limpiar_fecha"
                    @changeSize="changeSize"
                  />
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="codigo"
        :headers="headers"
        :items="inventarios"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item.codigo)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            v-if="item.estado == 2"
            icono="as fa-sync"
            color="success"
            title="Actualizar stock"
            :texto="`¿Desea sincronizar el stock del inventario Nº <strong>${item.codigo}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="actualizar_stock(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <DetalleInventario
      v-model="dialog"
      :codigo="codigo"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import FechaPickerRango from '../../components/util/FechaPickerRango'
import DetalleInventario from '../../components/auditoria/DetalleInventario'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      big: false,
      load: false,
      dialog: false,
      limpiar_fecha: false,
      codigo: null,
      bodegas: [],
      inventarios: [],
      headers: [
        { text: 'Inventario', value: 'codigo', align: 'end' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Referencia', value: 'referencia' },
        { text: 'Bodega', value: 'deposito' },
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Usuario', value: 'usuario' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' },
      ],
      filtro: {
        empresa: null,
        sucursal: null,
        inventario: null,
        deposito: null,
        articulo: null,
        fecha: [ null, null ]
      }
    }
  },
  computed: {
    ...mapState(['empresas', 'sucursales'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    FechaPickerRango,
    DetalleInventario
  },
  methods: {
    async actualizar_stock (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('inventario/actualizar_stock', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          item.estado = 5
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.inventarios = []
      await this.$store.dispatch('inventario/get_invantarios', this.filtro)
        .then((res) => {
          this.inventarios = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async get_bodegas () {
      const empresa = this.filtro.empresa
      const sucursal = this.filtro.sucursal
      if (empresa && sucursal) {
        this.filtro.deposito = null
        this.bodegas = []
        this.$store.state.loading = true
        await this.$store.dispatch('bodegas/get_bodegas', {
          inhabilitada: 0,
          empresa: empresa,
          sucursal: sucursal
        })
          .then((res) => {
            this.bodegas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.bodegas, 'nombre')
        this.$store.state.loading = false
      }
    },
    open_dialog (item) {
      this.codigo = item
      this.dialog = true
    },
    limpiar () {
      this.bodegas = []
      this.filtro = {
        empresa: null,
        sucursal: null,
        inventario: null,
        deposito: null,
        articulo: null,
        fecha: [ null, null ]
      }
      this.limpiar_fecha = true
    },
    changeSize (custom) {
      this.big = custom
    }
  }
}
</script>